import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const registrationGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const storage = inject(StorageService);

  // if (!storage.isAuthenticated()) {
  //   router.navigateByUrl('/auth/login');
  //   return false;
  // } else {
  //   // console.log('Executed', state.url);
  //   return state.url.includes('auth') ? router.navigateByUrl('/socielite-admin') : true;
  // }
  return true;
  
};
