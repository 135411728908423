import { Routes } from '@angular/router';
import { LoginPage } from './login/login.page';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { registrationGuard } from 'src/app/helpers/guard/registration.guard';

export const authRoutes: Routes = [
    { 
        path: '', 
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginPage,
        canActivate: [registrationGuard]
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [registrationGuard]
    }
];
