import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { TokenInterceptorService } from './app/helpers/services/token-interceptor.service';
import { NgxTinymceModule } from 'ngx-tinymce';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APIENDPOINT } from './app/helpers/constant/api-endpoints';
import { NgPipesModule } from 'ngx-pipes';

import { DropzoneModule, DropzoneConfigInterface,
  DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';

if (environment.production) {
  enableProdMode();
}

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  acceptedFiles: 'image/*',
  createImageThumbnails: true
};

export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http, environment.languageFilesPath, '.json');
  // console.log({createTranslateLoader : environment.baseUrl + APIENDPOINT.LANGUAGE.LOAD})
  return new TranslateHttpLoader(http, environment.baseUrl + APIENDPOINT.LANGUAGE.LOAD, '');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),

    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    provideCharts(withDefaultRegisterables()),
    importProvidersFrom(
      IonicModule,
      IonicStorageModule.forRoot({
        name: '_socielite',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
      NgxTinymceModule.forRoot({
        baseURL: './assets/tinymce/'
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      NgPipesModule,
      DropzoneModule
    )
  ],
});
