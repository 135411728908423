export const APIENDPOINT = {
    AUTH: {
        GETUSER:'auth/me',
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        FORGOTPASSWORD: 'auth/password-reset-email',
        RESETPASSWORD: 'auth/reset-password'
    },
    PROSPECT: {
        ACTIVE: 'prospect/active-prospects',
        CLOSED: 'prospect/closed-prospects',
        DETAIL: 'prospect/prospect-detail/', //prospectId
        ASSIGN: 'prospect/assign/', //prospectId/{user_id}
        CHANGESTATUS: 'prospect/change-status/', //prospect/change-status/{universityId}/{status}
        CHANGESTAGE: 'prospect/change-stage/', //prospect/change-stage/{universityId}/{stage}
        UPLOADDOCUMENT: (prospectId: number) => `prospect/${prospectId}/upload-document`, //prospect/{universityId}/upload-document
        DELETEDOCUMENT: 'prospect/delete-document/', // delete-document/{universityId}/{documentId}
        GETDOCUMENTS: (prospectId: number, offset: number) => `prospect/${prospectId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ADDNOTE: (prospectId: number) => `prospect/${prospectId}/add-note`, //prospect/{universityId}/add-note
        GETNOTES: (prospectId: number, offset: number) => `prospect/${prospectId}/get-notes/${offset}`, //prospect/{universityId}/add-note/{offset?},
        UPDATE: (prospectId: number) => `prospect/${prospectId}/update`,
        EDITNOTE: (universityId: number, noteId: number) => `prospect/${universityId}/edit-note/${noteId}`
    },
    CLIENT: {
        LIST: 'client/list', //list/{status}/{offset?}/{name?}
        DETAIL: 'client/client-detail/', //client/client-detail/{id}
        GETDOCUMENTS: (clientId: number, offset: number) => `client/${clientId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ASSIGN: 'prospect/assign/', //universityId/{user_id}
        CREATE: 'client/create',
        UPLOADDOCUMENT: (universityId: number) => `client/${universityId}/upload-document`,
        UPDATE: (universityId: number) => `client/${universityId}/update`,
        BILLINGDETAILS: 'client/billingdetails/', //{universityId}
        INVOICES: 'client/invoices/' //{universityID}/{offset}
    },
    USERS: {
        GETSOCIELITEADMINS: 'users/socielite-admins', //users/socielite-admins/{offset}/{name}
        STUDENTS: {
            ALL: 'users/students/all/', //{universityId}
            IMPORT: 'users/students/import'
        },
        EDITCURRENTUSER:'user/update'
        
    },
    QUERY: {
        LIST: (universityID : number) => `query/university/queries/${universityID}`, //{universityId}/{offset}
        CHATS: 'query/query-chats/', //{universityId}/{queryId}/{offset},
        REPLY: (queryID : number) => `query/socielite-admin/reply-query/${queryID}`, //{query_id, content, university_id, sender_id}
        ALL_QUERIES : 'query/all-queries', // GET
        UPDATE_STATUS : (queryID : number) => `query/socielite-admin/update-status/${queryID}`, // PUT
        QUERY_DETAILS : 'query/details', // GET
    },
    COURSES: {
        ALL: 'courses/all/', // {universityId}
        IMPORT: 'courses/import',
    },
    COMMUNICATON: {
        COMMUNICATION: 'communication/get/',
        LIST: 'communication/all-communication/',
        SEND: 'communication/send',
        EDIT: 'communication/edit/',
        PROFILE: 'communication/profile',
        EDITPROFILE: 'communication/profile/edit',
    },
    FAQ: {
        FAQ: 'faq/get/',
        LIST: 'faq/all-faq/',
        POST: 'faq/create',
        EDIT: 'faq/edit/',
        DELETE: 'faq/delete/'
    },
    TUTORIAL:{
        TUTORIAL:'tutorial/get/',
        LIST:'tutorial/all-tutorials',
        POST: 'tutorial/create',
        EDIT:'tutorial/edit/',
        DELETE:''
    },
    TEAMMEMBER:{
        TEAMMEMBER:'team-member/get/',
        LIST:'team-member/all/',//{teamId}
        POST:'team-member/get/',
        EDIT:'team-member/edit/', //{userId}
        ADD: 'team-member/add'
    },
    TEAM: {
        CREATE: 'team/create',
        ALL: 'team/list/',
        ROLESPERMISSIONS: 'team/roles-permissions/',
        UPDATEROLES: 'team/update-roles/'
    },
    STATISTIC:{
        GETACTIVEUSERS:'statistics/chart-data/',
        GETCARDDATA:'statistics/cards-data'
    },
    PERMISSIONS: {
        ALL: 'permissions/all'
    },

    // LOCALIZATION - BACKOFFICE
    LANGUAGE: {
        LOAD: 'language/load/', // {language}
        LOADALL: 'language/get',
        GETLIST: 'language/list', // {language?}
        NEW: 'language/create', // POST {name, code}
        SAVE: 'language/save/', // {language} POST {translations}
        PUBLISH: 'language/publish/', // {language} PUT
        UNPUBLISH: 'language/unpublish/', // {language} DELETE
    },
    EXCELTEMPLATE: {
        UPLOAD: 'excel-template/upload',
        GET: 'excel-template/' //{type}
    },
    ADMIN_DASHBOARD: {
        INVOICES_TRACKER: 'admin-dashboard/invoice-tracker',
        ADMINS: 'admin-dashboard/list-admin',
        RENEWAL_OVERVIEW: 'admin-dashboard/renewal-overview',
        STATISTICS: {
            TILES: 'admin-dashboard/statistics-tiles',
            USERS: 'admin-dashboard/statistics-users',
            SUBSCRIPTIONS: 'admin-dashboard/statistics-subscriptions',
        }
    }

}