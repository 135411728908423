import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

export const pageRestrictGuard: CanActivateChildFn = async (childRoute, state) => {

  const router = inject(Router);
  const storage = inject(StorageService);

  // if(storage.isAuthenticated()) {
  //   const role = await storage.getRole() as Array<string>;
  //   let url = state.url.startsWith('/') ? state.url.slice(1) : state.url;
    
  //   if (
  //     (url.startsWith('socielite-admin') && (role.length === 1 && role[0] !== 'socielite_admin')) ||
  //     (!url.startsWith('socielite-admin') && (role.length === 1 && role[0] === 'socielite_admin'))
  //   )  {
  //     console.log('/not-authorized');
  //     router.navigateByUrl('/not-authorized');
  //     return false;
  //   }
  // }

  return true;
};
