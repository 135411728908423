import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { GenericResponse } from '../constant/data-types';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient) { }

  //Main Service Methods for GET, POST, PUT, PATCH & DELETE
  public get<T  = void | any>(apiURL: string, reqInput?: any): Observable<GenericResponse<T>> {
    const paramInputs = { params: reqInput ? reqInput : undefined };
    return this.http.get<GenericResponse<T>>(apiURL, paramInputs).pipe(catchError(this.errorHandler));
  }

  public post<T  = void | any>(apiURL: string, body: any): Observable<GenericResponse<T>> {
    let headers = {};
    if (!(body instanceof FormData)) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    const httpOptions = { headers };
    return this.http.post<GenericResponse<T>>(apiURL, body, httpOptions).pipe(catchError(this.errorHandler));
  }

  public put<T  = void | any>(apiURL: string, body: any): Observable<GenericResponse<T>> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<GenericResponse<T>>(apiURL, body, httpOptions).pipe(catchError(this.errorHandler));
  }

  /**
   * @deprecated 
   * do not use patch method, since it does not work on production environment 
   * */
  public patch<T  = void | any>(apiURL: string, body: any): Observable<GenericResponse<T>> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.patch<GenericResponse<T>>(apiURL, body, httpOptions).pipe(catchError(this.errorHandler));
  }

  public delete<T  = void | any>(apiURL: string): Observable<GenericResponse<T>> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<GenericResponse<T>>(apiURL, httpOptions).pipe(catchError(this.errorHandler));
  }

  public deleteWithBody<T  = void | any>(apiURL: string, body: any): Observable<GenericResponse<T>> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body};
    return this.http.delete<GenericResponse<T>>(apiURL, httpOptions).pipe(catchError(this.errorHandler));
  }

  /**
   * Handles errors by returning an observable of the error.
   * 
   * @param error - The error to handle.
   * @returns An Observable that emits the error.
   */
  errorHandler(error:any) {
    return of(error);
  }


}
